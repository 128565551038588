@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@400;600;700&display=swap");
@layer base {
  html {
    font-family: "Hind Siliguri", sans-serif;
    color: #292830;
  }

  .product {
    background-image: url("./assets/img/bg-product.png");
  }

  .banner {
    background-image: url("./assets/img/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slick-slide {
    padding: 0 10px !important;
  }

  .slick-initialized .slick-slide {
    padding: 0 10px !important;
  }
}
